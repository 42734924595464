import axios from 'axios';
import he from 'he';
import React, { useEffect, useState } from 'react';
import PostModal from './modal';


// Logos
import Pmf from '../assets/img/logos/Pmf.svg';
import Adr from '../assets/img/logos/adr.svg';
import Ba from '../assets/img/logos/ba.svg';
import Bla from '../assets/img/logos/bla.svg';
import Ff from '../assets/img/logos/ff.svg';
import Froya from '../assets/img/logos/froya.svg';
import Gp from '../assets/img/logos/gp.svg';
import Hitra from '../assets/img/logos/hitra.svg';
import Inn from '../assets/img/logos/inn.svg';
import Kp from '../assets/img/logos/kp.svg';
import Mn24 from '../assets/img/logos/mn24.svg';
import Opp from '../assets/img/logos/opp.svg';
import S24 from '../assets/img/logos/s24.svg';
import St from '../assets/img/logos/st.svg';
import Tb from '../assets/img/logos/tb.svg';

const FilterList = ({
	activeMonth,
	selectedCategories,
	currentMonth,
	months,
}) => {
	const [posts, setPosts] = useState({ bilag: [], tema: [], kampanje: [], merkedager: [] });
	const [selectedPost, setSelectedPost] = useState(null);


	const formatDate = (dateStr) => {
		if (dateStr && dateStr.length === 8) {
			const year = dateStr.substring(0, 4);
			const month = dateStr.substring(4, 6);
			const day = dateStr.substring(6, 8);
			return {
				year: parseInt(year, 10),
				month: parseInt(month, 10),
				day: parseInt(day, 10),
				formatted: `${day}.${month}`,
				sortable: `${year}${month}${day}`,
			};
		}
		return null;
	};

	const getMonthFromDateString = (dateString) => {
		if (dateString && dateString.length === 5) {
			const parts = dateString.split('.');
			return parseInt(parts[1], 10); // Get the month part
		}
		return null;
	};

	const fetchFileUrlById = async (fileId) => {
		try {
			const response = await axios.get(
				`${process.env.REACT_APP_API_URL}/wp-json/wp/v2/media/${fileId}`
			);
			return response.data.source_url; // This is where the URL is usually found
		} catch (error) {
			console.error('Error fetching file URL:', error);
			return ''; // Return empty string or some default value on error
		}
	};

	useEffect(() => {
		const fetchData = async () => {
			try {
				let page = 1;
				let hasMore = true;
				let allPosts = [];
				const categorizedPosts = { bilag: [], tema: [], kampanje: [], merkedager: [] };

				while (hasMore) {
					const response = await axios.get(
						`${process.env.REACT_APP_API_URL}/wp-json/wp/v2/posts?per_page=100&page=${page}`
					);
				
					// Check if there are any posts in the response
					if (response.data.length === 0) {
						hasMore = false; // Stop pagination if no more posts are returned
						break;
					}
				
					allPosts = allPosts.concat(response.data);
				
					// Check total posts using the X-WP-TotalPages header
					const totalPages = parseInt(response.headers['x-wp-totalpages'], 10);
					if (page >= totalPages) {
						hasMore = false; // No more pages to fetch
					} else {
						page++; // Fetch the next page
					}
				}

				// Process all posts
				for (const post of allPosts) {
					const omrade = post.acf.omrade;
					const dateInfo = formatDate(post.acf.dato);
					const urlInfo = post.acf.url;
					let pdfUrl = ''; // Initialize pdfUrl

					// If there's a PDF ID, fetch its URL
					if (post.acf.pdf) {
						pdfUrl = await fetchFileUrlById(post.acf.pdf);
					}

					// Push the post data along with the pdfUrl
					if (categorizedPosts[omrade] && dateInfo) {
						categorizedPosts[omrade].push({
							id: post.id, // Add the post ID
							title: post.title.rendered,
							date: dateInfo,
							categories: post.categories,
							url: urlInfo,
							pdf: pdfUrl,
						});
					}
				}

				setPosts(categorizedPosts);
			} catch (error) {
				console.error('Error fetching posts:', error);
			}
		};

		fetchData(); // Call the async function
	}, [activeMonth]);

	const getFilteredPosts = (postsArray, activeMonth, selectedCategories, months) => {
		const currentYear = new Date().getFullYear();
		const globalCategoryId = 3; // ID for "Polaris Media Felles"
	
		const filtered = postsArray.filter((post) => {
			if (!post.date) {
				return false;
			}
	
			// Filter by year
			if (post.date.year !== currentYear) {
				return false;
			}
	
			// Determine if the post is in the selected categories
			const isGlobalPost = post.categories.includes(globalCategoryId);
			const isInSelectedCategories =
				selectedCategories.length === 0 ||
				post.categories.some((categoryId) => selectedCategories.includes(categoryId));
	
			// Check if the post matches the selected month or "Hele året"
			const isCorrectMonth =
				activeMonth === months.length || // Last month is "Hele året"
				post.date.month === activeMonth;
	
			// Include global posts along with the selected category posts
			return (isInSelectedCategories || isGlobalPost) && isCorrectMonth;
		});
	
		const sorted = filtered.sort((a, b) => {
			// Convert dates to a comparable format (YYYYMMDD)
			const dateA = `${a.date.year
				.toString()
				.padStart(4, '0')}${a.date.month
				.toString()
				.padStart(2, '0')}${a.date.day.toString().padStart(2, '0')}`;
			const dateB = `${b.date.year
				.toString()
				.padStart(4, '0')}${b.date.month
				.toString()
				.padStart(2, '0')}${b.date.day.toString().padStart(2, '0')}`;
			return dateA.localeCompare(dateB);
		});
	
		return sorted;
	};

	function getLogo(category) {
		switch (category) {
			case 3:
				return Pmf;
			case 4:
				return Adr;
			case 5:
				return Tb;
			case 6:
				return Mn24;
			case 10:
				return Bla;
			case 11:
				return Ba;
			case 12:
				return Ff;
			case 13:
				return Hitra;
			case 14:
				return Froya;
			case 15:
				return Inn;
			case 16:
				return Opp;
			case 17:
				return St;
			case 18:
				return S24;
			case 19:
				return Kp;
			case 20:
				return Gp;
			default:
				return Adr;
		}
	}

	const fetchPostDetails = async (postId) => {
		try {
			const response = await axios.get(
				`${process.env.REACT_APP_API_URL}/wp-json/wp/v2/posts/${postId}`
			);
			setSelectedPost(response.data); // Update `selectedPost` with full post data
		} catch (error) {
			console.error('Error fetching post details:', error);
		}
	};

	return (
		<section className='filterList w-full 2xl:w-3/4 mx-auto mt-8 px-6 lg:px-12 2xl:px-0'>
			<div className='content flex flex-col xl:flex-row justify-between'>
				<div className='bilag'>
					<h3 className='text-2xl'>Bilag</h3>

					<div className='list mt-4'>
						{getFilteredPosts(
							posts.bilag,
							activeMonth,
							selectedCategories,
							months
						).map((post, index) => (
							<div
								key={index}
								className='item flex flex-row justify-between items-center'
								onClick={(e) => {
									e.preventDefault(); // Prevent default anchor behavior
									fetchPostDetails(post.id); // Fetch full details of the clicked post
								}}
							>
								<a
									href="#"
									rel='noopener noreferrer'
								>
									<h2>{he.decode(post.title)}</h2>
									<h2>{post.date.formatted}</h2>{' '}
									{/* Use the formatted date for display */}
								</a>
								<img src={getLogo(post.categories[0])} alt='' />
							</div>
						))}
					</div>
				</div>

				<div className='tema'>
					<h3 className='text-2xl'>Tema</h3>
					<div className='list mt-4'>
						{getFilteredPosts(
							posts.tema,
							activeMonth,
							selectedCategories,
							months
						).map((post, index) => (
							<div
								key={index}
								className='item flex flex-row justify-between items-center'
								onClick={(e) => {
									e.preventDefault(); // Prevent default anchor behavior
									fetchPostDetails(post.id); // Fetch full details of the clicked post
								}}
							>
								<a
									href="#"
									rel='noopener noreferrer'
								>
									<h2>{he.decode(post.title)}</h2>
									<h2>{post.date.formatted}</h2>{' '}
									{/* Use the formatted date for display */}
								</a>
								<img src={getLogo(post.categories[0])} alt='' />
							</div>
						))}
					</div>
				</div>

				<div className='kampanje'>
					<h3 className='text-2xl'>Kampanje</h3>
					<div className='list mt-4'>
						{getFilteredPosts(
							posts.kampanje,
							activeMonth,
							selectedCategories,
							months
						).map((post, index) => (
							<div
								key={index}
								className='item flex flex-row justify-between items-center'
								onClick={(e) => {
									e.preventDefault(); // Prevent default anchor behavior
									fetchPostDetails(post.id); // Fetch full details of the clicked post
								}}
							>
								<a
									href="#"
									rel='noopener noreferrer'
								>
									<h2>{he.decode(post.title)}</h2>
									<h2>{post.date.formatted}</h2>{' '}
									{/* Use the formatted date for display */}
								</a>
								<img src={getLogo(post.categories[0])} alt='' />
							</div>
						))}
					</div>
				</div>

				<div className="merkedager">
					<h3 className="text-2xl">Merkedager</h3>
					<div className="list mt-4">
						{getFilteredPosts(
							posts.merkedager,
							activeMonth,
							selectedCategories,
							months
						).map((post, index) => (
							<div
								key={index}
								className="item flex flex-row justify-between items-center"
								onClick={(e) => {
									e.preventDefault(); // Prevent default anchor behavior
									fetchPostDetails(post.id); // Fetch full details of the clicked post
								}}
							>
								<a
									href="#"
									rel="noopener noreferrer"
								>
									<h2>{he.decode(post.title)}</h2>
									<h2>{post.date.formatted}</h2>
								</a>
								<img src={getLogo(post.categories[0])} alt="" />
							</div>
						))}
					</div>
				</div>
			</div>

			{selectedPost && (
				<PostModal
					post={selectedPost}
					onClose={() => setSelectedPost(null)} // Close the modal by setting selectedPost to null
				/>
			)}
		</section>
	);
};

export default FilterList;
