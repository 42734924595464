import React, { useState, useEffect } from 'react';
import he from 'he';
import { AiOutlineFilePdf } from 'react-icons/ai'; // For PDF icon
import { HiOutlineNewspaper } from 'react-icons/hi'; // For Newspaper icon
import '../assets/scss/modal.scss';
import axios from 'axios'; // To fetch the file URL

const formatDate = (dateStr) => {
    if (dateStr && dateStr.length === 8) {
        const day = dateStr.substring(6, 8);
        const month = dateStr.substring(4, 6);
        const year = dateStr.substring(0, 4);
        return `${day}.${month}.${year}`;
    }
    return 'Ukjent dato'; // Fallback for invalid date
};

// Format price to Norwegian format
const formatPrice = (price) => {
    if (!price) return '0 ,-';
    return `${price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')} ,-`;
};

const PostModal = ({ post, onClose }) => {
    const [pdfUrl, setPdfUrl] = useState(null); // State for the actual PDF URL

    const landingssideInfo = post?.acf?.landingsside_info; // Safely access group fields
    const priceInfo = landingssideInfo?.priser; // Access the group field for price details

    // Fetch the PDF URL if it's an ID
    useEffect(() => {
        const fetchPdfUrl = async () => {
            if (landingssideInfo?.pdf) {
                // Check if pdf is a number (ID) or already a URL
                if (typeof landingssideInfo.pdf === 'number') {
                    try {
                        const response = await axios.get(
                            `${process.env.REACT_APP_API_URL}/wp-json/wp/v2/media/${landingssideInfo.pdf}`
                        );
                        setPdfUrl(response.data.source_url); // Set the actual URL
                    } catch (error) {
                        console.error('Error fetching PDF URL:', error);
                        setPdfUrl(null); // Reset to null in case of an error
                    }
                } else {
                    setPdfUrl(landingssideInfo.pdf); // Use the provided URL if it’s already correct
                }
            }
        };

        fetchPdfUrl();
    }, [landingssideInfo?.pdf]);

    if (!post) return null; // Don't render if no post is selected

    return (
        <div className="modal">
            <div className="modal-content">
                {/* Close Button */}
                <button className="close-button" onClick={onClose}>
                    Close
                </button>

                {/* Post Content */}
                <h2>{he.decode(post.title?.rendered || '')}</h2>
                <p>
                    <strong>Dato:</strong> {post.acf?.dato ? formatDate(post.acf.dato) : 'Ukjent dato'}
                </p>

                {/* Render landingsside_info only if it exists */}
                {landingssideInfo ? (
                    <div>
                        <p>
                            <strong>Beskrivelse:</strong>{' '}
                            {landingssideInfo.beskrivelse_av_aktivitet}
                        </p>

                        {/* Priser */}
                        {priceInfo && (
                            <div className="prices">
                                <h3>Pris:</h3>
                                <p>
                                    <strong>{priceInfo.prisnavn}:</strong>{' '}
                                    {formatPrice(priceInfo.pris)}
                                </p>
                            </div>
                        )}

                        {/* Dates Section */}
                        <div className="dates-grid">
                            <div>
                                <p>
                                    <strong>Frist for bestilling:</strong>{' '}
                                    {formatDate(landingssideInfo.frist_for_bestilling)}
                                </p>
                                <p>
                                    <strong>Frist for materiell:</strong>{' '}
                                    {formatDate(landingssideInfo.frist_for_materiell)}
                                </p>
                            </div>
                            <div>
                                <p>
                                    <strong>Salgsperiode fra:</strong>{' '}
                                    {formatDate(landingssideInfo.salgsperiode_fra)}
                                </p>
                                <p>
                                    <strong>Salgsperiode til:</strong>{' '}
                                    {formatDate(landingssideInfo.salgsperiode_til)}
                                </p>
                            </div>
                        </div>

                        {/* Links Section */}
                        <div className="links-row">
                            {pdfUrl && (
                                <a
                                    href={pdfUrl}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="link"
                                >
                                    <AiOutlineFilePdf className="icon" />
                                    Last ned PDF
                                </a>
                            )}
                            {landingssideInfo.lenke_til_salgsbrev && (
                                <a
                                    href={landingssideInfo.lenke_til_salgsbrev}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="link"
                                >
                                    <HiOutlineNewspaper className="icon" />
                                    Les salgsbrev
                                </a>
                            )}
                        </div>
                    </div>
                ) : (
                    <p className="no-info">
                        Ingen ekstra informasjon tilgjengelig for denne posten.
                    </p>
                )}
            </div>
        </div>
    );
};

export default PostModal;
